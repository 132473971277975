import React, { Component } from 'react';
import content from '../helper/content';
import InputComponent from './InputComponent';
import { CircularProgress } from '@mui/material';
import {   
  createMedia,   
  OrderRegistration, 
  registrationApiFormatter, 
  getProductsDetails 
} from '../services/warrentyIntegration';
import { Col, Row } from 'reactstrap';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SmallLoader from "./smallLoader";

class LandingPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      selectedFile: null,
      submitted: false,
      showWhereToBuyInput:false,
      showReasonForRequest: false,
      showForm: false,
      showLoader: true,
      productInfo: null,
      showScreenType: true,
      formData: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        emailAddress: '',
        dateOfPurchase: '',
        reasonForRequest: '',
        purchasedFrom: '',
        modelNumber: '',
        buyInput: '',
        reasonForRequestInput: '',
        fileUpload: '',
        optin1: false,
        orderId: ""
      },
      errors: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        emailAddress: '',
        dateOfPurchase: '',
        reasonForRequest: '',
        reasonForRequestInput: false,
        modelNumber: '',
        buyInput: false,
        purchasedFrom: '',
        fileUpload: ''
      }
    };
  }

  sortProductName = (a, b) => {       
    return a.sequenceOrder - b.sequenceOrder;
  }

  componentDidMount = () => {    
    getProductsDetails().then(product => {      
      let val = product.sort(this.sortProductName);      
      this.setState({ productInfo: val, showForm: true, showLoader: false });
    });
    this.updateDimension();
    window.addEventListener('resize', this.updateDimension);
  }

  updateDimension = () => {
    if(window.innerWidth > 767) {
      this.setState({ showScreenType: true });
    } else {
      this.setState({ showScreenType: false });
    }
  }
  
  handleChange = (event) => {
    let targets = { ...event };
    const { name } = event.target;
    const value = name === 'optin1' ? event.target.checked: event.target.value;
    if(name === 'purchasedFrom') {
      if(value === content.whereToBuy.options[8]) {
        this.setState(prevState => ({
          showWhereToBuyInput: true,
          errors: {
            ...prevState.errors,
            buyInput: ''
          }
        }));
      } else {
        this.setState(prevState => ({
          showWhereToBuyInput: false,
          formData: {
            ...prevState.formData,
            buyInput: ''
          },
          errors: {
            ...prevState.errors,
            buyInput: false
          }
        }));
      }
    }
    if(name === 'reasonForRequest') {
      if(value === content.reasonToBuy.options[7]) {
        this.setState(prevState => ({
          showReasonForRequest: true,
          errors: {
            ...prevState.errors,
            reasonForRequestInput: ''
          }
        }));
      } else {
        this.setState(prevState => ({
          showReasonForRequest: false,
          formData: {
            ...prevState.formData,
            reasonForRequestInput: ''
          },
          errors: {
            ...prevState.errors,
            reasonForRequestInput: false
          }
        }));
      }
    }
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: false
      }
    }), () => {
      if(name !== 'optin1')
        this.validateField(targets);
    });
  }
  validateField = (event) => {
    const { name, validity } = event.target;
    if(!this.state.formData[name] || this.state.formData[name].trim() === '') {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [name]: ''
        },
        errors: {
          ...prevState.errors,
          [name]: content.fieldIsRequired
        }
      }));
    } else {
      if(name === 'firstName' || name === 'lastName' || name === 'buyInput' || name === 'reasonForRequestInput') {
        //eslint-disable-next-line
        var namePattern = new RegExp(/^[^0-9\]\[{}&^%$@#*\'\";()\/\\+=<>~`?]+$/);
        var targetValue =this.state.formData[name] || 's';
        if(!namePattern.test(targetValue)) {
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              [name]: content.pleaseEnterValidInput
            }
          }));
        } else {
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              [name]: false
            }
          }));
        }
      } else if(validity.patternMismatch) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [name]: content.pleaseEnterValidInput
          }
        }));
      } else {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [name]: false
          }
        }));
      }
    }
  }

  onFileSelect = (event) => {
    const file = event.target.files;
    if(file && file[0] && file.length &&
        (
          file[0].type === 'image/jpg' ||
          file[0].type === 'image/jpeg' ||
          file[0].type === 'image/png' ||
          file[0].type === 'image/gif' ||
          file[0].type === 'application/pdf'
        )
      ) {
        if(file[0].size > 4194304) {
          event.target.value = '';
          this.setState(prevState => ({
            formData: {
              ...prevState.formData,
              fileUpload: ''
            },
            errors: {
              ...prevState.errors,
              fileUpload: content.pleaseUploadMaximum4MB
            }
          }));
        } else {
          const fileInfo = {
            imageName: file[0].name,
            fileObject: file[0]
          };
          this.setState(prevState => ({
            selectedFile: fileInfo,
            errors: {
              ...prevState.errors,
              fileUpload: false
            }
          }));
        }
    } else {
      event.target.value = '';
      this.setState(prevState => ({
        selectedFile: '',
        formData: {
          ...prevState.formData,
          fileUpload: ''
        },
        errors: {
          ...prevState.errors,
          fileUpload: content.pleaseUploadImagePdfFile
        }
      }));
    }
   }

  handleDateChange = (date, name) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: date
      },
      errors: {
        ...prevState.errors,
        [name]: false
      }
    }));
  }

  validateDate = (name) => {
		const { formData } = this.state;
		if (!formData[name]) {
			this.setState(prevState => ({
				errors: {
					...prevState.errors,
					[name]: content.fieldIsRequired
				}
			}));
		}
  }

  validateForm = (errors) => {
    let valid = true;
    Object.entries(errors).forEach(value => {
      if(value[1] === '') {
        valid = false;
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [value[0]] : content.fieldIsRequired
          },
          loader: false
        }));
        valid = false;
      } else if (value[1].length > 0) {
        valid = false;
      }
    });
    return valid;
  }
  
  submitForm = (event) => {
    event.preventDefault();
    const valid = this.validateForm(this.state.errors);
    if(valid) {
      this.setState(
        {
          loader: true
        }, () => {
          let fileData = new FormData();
          const { selectedFile } = this.state;
          let randomName =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          fileData.append('file', selectedFile.fileObject, randomName + selectedFile.imageName);          
          createMedia(fileData).then((mediaDetails) => {              
            const mediaId = mediaDetails.fileId;
            this.setState(
              (prevState) => ({
                formData: {
                  ...prevState.formData,
                  fileUpload: [mediaId],
                },
              }),
              () => {                 
                OrderRegistration(
                  registrationApiFormatter(this.state)
                )
                  .then(() => {
                    this.setState(prevState => ({
                      formData: {
                        ...prevState.formData                          
                      },                        
                    }));    
                    this.setState({ loader: false, submitted: true });                    
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                  });                                        
              }
            );
          })
          .catch(() => {
            this.setState((prevState) => ({
              loader: false,
              errors: {
                ...prevState.errors,
                fileUpload: content.errorInFileUpload,
              },
            }));
          });
        }
      );
    }
  }

  clearFile = () => {
    this.setState(prevState => ({
      selectedFile: '',
      formData: {
        ...prevState.formData,
        fileUpload: ''
      },
      errors: {
        ...prevState.errors,
        fileUpload: content.pleaseUploadImagePdfFile
      }
    }));
  }

  render () {
    const { loader, showWhereToBuyInput, showReasonForRequest, formData, errors, showForm, showLoader, selectedFile, submitted, productInfo, showScreenType } = this.state;
    const props = {
      formData: this.state.formData,
      errors: this.state.errors,
      handleChange: this.handleChange,
      validateField: this.validateField,
      onFileSelect: this.onFileSelect,
      handleDateChange: this.handleDateChange,
      validateDate: this.validateDate,
      clearFile: this.clearFile
    };

    const consumerInputs = [{
        title: content.firstName,
        name: 'firstName',
        pattern: content.onlyLetterRegex,
        isInput: true,
        placeholder: '輸入您的名字',
        englishContent: content.english.firstName
      }, {
        title: content.lastName,
        name: 'lastName',
        pattern: content.onlyLetterRegex,
        isInput: true,
        placeholder: '輸入您的姓氏',
        englishContent: content.english.lastName
      }, {
        title: content.contactNumber,
        name: 'mobileNumber',
        pattern: content.phoneNumberPattern,
        isInput: true,
        placeholder: '輸入您的聯絡電話',
        englishContent: content.english.contactNumber
      }, {
        title: content.emailAddress,
        name: 'emailAddress',
        pattern: content.emailPattern,
        isInput: true,
        placeholder: '輸入您的電郵地址',
        englishContent: content.english.emailAddress
      }
    ];
    const products = [{
      name: 'purchasedFrom',
      title: content.whereToBuy.title,
      isSelect: true,
      options: content.whereToBuy.options,
      englishContent: content.english.whereToBuy.title
    }, {
      name: 'dateOfPurchase',
      title: content.purchaseDate,
      isDatePicker: true,
      class: 'datePicket',
      englishContent: content.english.purchaseDate
    }, {
      name: 'modelNumber',
      title: content.modelNumber.title,
      isSelect: true,
      options: productInfo ? productInfo: [],
      englishContent: content.english.modelNumber.title
    }, {
      name: 'reasonForRequest',
      title: content.reasonToBuy.title,
      dropDownPlaceholder: content.reasonToBuy.placeholder,
      dropDownEnglishPlaceholder: content.english.reasonToBuy.placeholder,
      isSelect: true,
      options: content.reasonToBuy.options,
      englishContent: content.english.reasonToBuy.title
    }, {
      name: 'fileUpload',
      title: content.uploadReceipt,
      isFileUpload: true,
      fileTitla: content.upload,
      class: 'fileUpload',
      selectedFile:selectedFile,
      englishContent: content.english.uploadReceipt,
      uploadEnglishContent: 'Upload'
    }];
    consumerInputs.map(field => Object.assign(field, props));
    if(showWhereToBuyInput) {
      const buyInput = {
        name: 'buyInput',
        title: content.whereToBuy.buyInputTitle,
        pattern: content.onlyLetterRegex,
        isInput: true
      };
      products.splice(1, 0, buyInput);
    }
    if(showReasonForRequest) {
      const reasonForRequestInput = {
        name: 'reasonForRequestInput',
        title: content.reasonToBuy.reasonInputTitle,
        pattern: content.onlyLetterRegex,
        isInput: true
      };
      products.splice(showWhereToBuyInput ? 5: 4, 0, reasonForRequestInput);
    }
    products.map(field => Object.assign(field, props));
    return (
      <>
      {showForm && (
        <section className='landingPageSection'>
          <div className='landingPage'>
            <div className='bannerBox'>
              {!submitted && <div className='banner'>
                <img
                  src={showScreenType ? content.bannerDesktopLogo : content.bannerMobileLogo}
                  className='bannerImg'
                  alt='BANNER'
                />
                <div className='titles'>
                  <label className='simpleRegistration'>{content.simpleRegistration}</label>
                  <label className='simpleRegistration eng'>{content.english.warrantyRegistration}</label>
                  <label className='totalWarrenty'>
                    {content.HKAdditionalYear + '  '} 
                    <img
                      src={content.braunSubTitleLogo}
                      className='braunSubtitleLogo'
                      alt='braunSubtitleLogo'
                    />
                    {'  ' + content.factoryMaintananceYear}
                  </label>
                  <label className='totalWarrenty'>
                    {content.english.HKAdditionalYear} 
                    <img
                      src={content.braunSubTitleLogo}
                      className='braunSubtitleLogo'
                      alt='braunSubtitleLogo'
                    />
                    {content.english.factoryMaintananceYear}
                  </label>
                </div>
              </div>}
              {!submitted && <p className='registrationMethods'>{content.registrationQualificationUsageMethods}</p> }
              {!submitted && <p className='registrationMethods eng'>{content.english.registrationQualificationUsageMethods}</p> }
              {!submitted && <p className='bannerContent'>{content.braunBannerContent}</p> }
              {!submitted && <p className='bannerContent eng'>{content.english.braunBannerContent}</p> }
              <div className={`formTitle ${submitted && 'thankPageFormTitle'}`}>
                <span className='userIcon'>
                  <svg id="person_add-24px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path id="Path_3" data-name="Path 3" d="M0,0H24V24H0Z" fill="none"/>
                  <path id="Path_4" data-name="Path 4" d="M15,12a4,4,0,1,0-4-4A4,4,0,0,0,15,12ZM6,10V7H4v3H1v2H4v3H6V12H9V10Zm9,4c-2.67,0-8,1.34-8,4v2H23V18C23,15.34,17.67,14,15,14Z" fill="#fff"/>
                  </svg>
                </span>
                <p className='formRegistration'>{submitted ? content.thanksRegistration : content.simpleRegistration}</p>
                <p className='formRegistration'>{submitted ? content.english.thanksRegistration : content.english.simpleRegistration}</p>
              </div>
            </div>
            {!submitted ? (<div className='formContainer'>
              <div className='formInputs'>
                <div className='form'>
                <p className='personalInformation'>{content.personalInformation} | {content.english.personalInformation}</p>
                  <div className='personalBox'>
                    <Row>
                      <Col md={{ size:4, offset: 2 }} lg={{ size:3, offset: 3 }} className={ 'firstName inputsTypes'}>
                        <InputComponent {...consumerInputs[0]} key='inputs-1' />
                      </Col>
                      <Col  md={{ size:4 }} lg={{ size:3 }} className={ 'inputsTypes'}>
                        <InputComponent {...consumerInputs[1]} key='inputs-2' />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ size:4, offset: 2 }} lg={{ size:3, offset: 3 }} className='inputsTypes'>
                        <InputComponent {...consumerInputs[2]} key='inputs-3' />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ size:4, offset: 2 }} lg={{ size:3, offset: 3 }} className='inputsTypes'>
                        <InputComponent {...consumerInputs[3]} key='inputs-4' />
                      </Col>
                    </Row>
                  </div>
                  <p className='details'>{content.details}{' | Purchase Details'}</p>
                  <div className='detailBox personalBox'>
                    {products.map((product, index) => {
                      return (
                        <Row key={`product-${index}`}>
                          <Col md={{ size:4 }} lg={{ size:3 }} className='detailsCols'>
                            <InputComponent {...product} />
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='form termsAndCondition'>
                <p className='title'>{content.termsAndConditions.title}{' | Terms & Conditions'}</p>
                {content.termsAndConditions.list.map((terms, index) => {
                  return <label className='termsLabels' key={`terms-${index}`}>{terms}<br />{content.termsAndConditions.englistList[index]}</label>;
                })}
                <div className='termsContents'>
                  <p className='registeringPandG'>{content.optIns[0]}</p>
                  <p className='registeringPandG eng'>{content.english.optIns[0]}</p>
                  <p className='termsRules'>
                    {content.optIns[1]}
                    <a rel="noreferrer" href='https://privacypolicy.pg.com/zht-TW/' target='_blank' className='optinLink'>{content.optIns[2]}</a>
                    {content.optIns[3]}
                    <a rel="noreferrer" href='https://termsandconditions.pg.com/zh-hk/' target='_blank' className='optinLink'>{content.optIns[4]}</a>
                    {content.optIns[5]}
                    <a rel="noreferrer" href='https://o2o-pg.com/hk/45/privacy' target='_blank' className='optinLink'>{content.optIns[6]}</a>
                    {content.optIns[7]}
                  </p>
                  <p className='termsRules eng'>
                    {content.english.optIns[1]}
                    <a rel="noreferrer" href='https://privacypolicy.pg.com/zht-TW/' target='_blank' className='optinLink'>{content.english.optIns[2]}</a>
                    {content.english.optIns[3]}
                    <a rel="noreferrer" href='https://termsandconditions.pg.com/zh-hk/' target='_blank' className='optinLink'>{content.english.optIns[4]}</a>
                    {content.english.optIns[5]}
                    <a rel="noreferrer" href='https://o2o-pg.com/hk/45/privacy' target='_blank' className='optinLink'>{content.english.optIns[6]}</a>
                    {content.english.optIns[7]}
                  </p>
                  <ul className='optInsContent'>
                    <li>{content.optIns[8]} <br />{content.english.optIns[8]}</li>
                    <li>{content.optIns[9]} <br />{content.english.optIns[9]}</li>
                    <li>{content.optIns[10]} <br />{content.english.optIns[10]}</li>
                    <li>{content.optIns[11]} <br />{content.english.optIns[11]}</li>
                  </ul>
                </div>                
                  <div>
                    <div className='agree'>
                      <label className='optinText'>                        
                        <FormGroup>
                            <FormControlLabel 
                              control={<Checkbox />} 
                              name='optin1' 
                              id='optin1' 
                              checked={formData.optin1} 
                              onChange={this.handleChange} 
                              placeholder="optin text" 
                              aria-label="optin text"
                            />
                          {content.optIns[12]}
                          <br />
                          {content.english.optIns[12]}
                          </FormGroup>
                      </label>
                    </div>
                    <div className='optinError'><p className='error'>{errors['optin1'] || ''}</p></div>
                  </div>
                {/* } */}
                <div className='submitBox'>
                  
                  {
                    loader ? <div className='loader'><CircularProgress /></div> : 
                    (
                      <Row className='submitRow'>
                        <Col md={{ size:4 }} lg={{ size:3 }} className='detailsCols'>
                          <button className='submit' onClick={(e) => this.submitForm(e)}>{content.submit + ' | I Agree'}</button>
                        </Col>
                      </Row>
                    )
                  }
                </div>
              </div>
            </div>): (
              <div className='thanksPage'>
                <p>{content.thanksContent}</p>
                <p>{content.english.thanksContent}</p>
              </div>
            )}
          </div>
        </section>
      )}
      {showLoader && <SmallLoader/>}
      </>
    );
  }
}

export default LandingPageComponent;