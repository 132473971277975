import axios from 'axios';
import keys from './config';
const environment = process.env.REACT_APP_ENVIRONMENT;
const access_token = environment === 'PRODUCTION' ? keys.production : keys.stage;
const headers = {
  'headers': {
    'Content-Type': 'application/json',
    'access_token': access_token
  }
};
export const post = async (url, data, header = null) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, header || headers)
      .then(response => {
        if(response.status === 200 || response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error.response ? error.response.data : error);
      });
  });
};

export const get = async (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, headers)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error.response ? error.response.data : error);
      });
  });
};