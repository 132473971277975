import React from 'react';
import './App.css';
import LandingPageComponent from './components/landingPage';
import content from './helper/content';

function App() {
  const findLocation = window.location.pathname === '/';
  return (
    <div className="App">
      {findLocation ? <LandingPageComponent /> : (
        <div className='underMaintance'>
          <img
              src={content.braunSubTitleLogo}
              className='projectTitle'
              alt='projectBanner'
            />
          <p className='errorMsg'>维护现场</p>
        </div>
      )}
    </div>
  );
}

export default App;
