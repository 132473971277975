import React, { useState, useRef, Component } from 'react';
import DatePicker from 'react-datepicker';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import content from '../helper/content';
import { addDays } from 'date-fns';

class CustomerDatePickerComponent extends Component {

  onClickDate = (e) => {
    e.preventDefault();
    this.props.onClick();
  }

  render() {
    const { value, dpplaceholder } = this.props;
    const selectedDate = new Date(value);
    return (
      <button className="datepicker" onClick={(e) => this.onClickDate(e)}>
        {value ? selectedDate.getFullYear() + '年' + (selectedDate.getMonth() + 1) + '月' + selectedDate.getDate() + '日' : dpplaceholder}
      </button>
    );
  }
}

const InputComponent = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const myRef = useRef(null);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const clearFile = () => {
    myRef.current.value = '';
    props.clearFile();
  };

  const {
    handleChange,
    name,
    title,
    dropDownPlaceholder,
    pattern,
    errors,
    options,
    formData,
    validateField,
    handleDateChange,
    validateDate,
    isInput,
    isSelect,
    isFileUpload,
    isDatePicker,
    onFileSelect,
    selectedFile,
    placeholder,
    dropDownEnglishPlaceholder,
    englishContent,
    uploadEnglishContent
  } = props;
  const startDate = new Date();
  const pastDate = startDate.getFullYear()-5;
  startDate.setFullYear(pastDate);
  return (
    <div className='inputs'>
      <label>{title}{`${englishContent ? ' | ' + englishContent : ''}`}</label>
      {isInput &&
        <input
          required
          type='text'
          onChange={handleChange}
          onBlur={validateField}
          name={name}
          pattern={pattern}
          value={formData[name]}
          placeholder={placeholder}
          // errors={errors[name] || ''}
          className='inputField'
          aria-required='true'
          aria-invalid='true'
        />
      }
      {isSelect &&
        <Dropdown className='select-field' isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className='labels' caret>
            {formData[name] || `${content.pleaseSelect}  ${dropDownPlaceholder || title} | ${content.english.pleaseSelect}  ${dropDownEnglishPlaceholder || englishContent}`}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              value=''
              name={name}
              onClick={e => handleChange(e)}
              onBlur={e => validateField(e)}
            >{`${content.pleaseSelect}  ${dropDownPlaceholder || title} | ${content.english.pleaseSelect}  ${dropDownEnglishPlaceholder || englishContent}`}</DropdownItem>
            {options.map((option, index) => (
              <DropdownItem
                value={name === "modelNumber" ? option.name : option}
                name={name}
                onClick={e => handleChange(e)}
                onBlur={e => validateField(e)}
                key={`name-${index}`}
              >
                {name === "modelNumber" ? option.name : option}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      }
      {isDatePicker &&
        <DatePicker
          className='datepicker'
          name={name}
          onChange={date => handleDateChange(date, name)}
          selected={formData[name]}
          onCalendarClose={() => validateDate(name)}
          minDate={startDate}
          maxDate={addDays(new Date(), 0)}
          showMonthDropdown
          showYearDropdown
          autoComplete='off'
          dropdownMode='select'
          customInput={<CustomerDatePickerComponent dpplaceholder={`${content.pleaseSelect} ${title} | ${content.english.pleaseSelect} ${englishContent}`} />}
        />
      }
      {isFileUpload &&
        <div className='fileUpload'>
          <label className='fileUploadLabel'>
            <input
              type='file'
              required
              id={name}
              title=''
              className={`fileUploadInput ${selectedFile && "fileUploadedInput"}`}
              placeholder="上載香港零售商發出的單據或網購收據 | Upload Official Invoice" 
              aria-label="上載香港零售商發出的單據或網購收據 | Upload Official Invoice"
              accept='.jpg, .jpeg, .png, .pdf, .gif'
              onChange={onFileSelect}
              ref={myRef}
            />          
            {selectedFile && <span className='closeIcon' onClick={clearFile}>X</span>}
            {selectedFile ? (content.clearImage + `${content.english.clearImage ? ' | ' + content.english.clearImage : ''}`) : content.upload + `${uploadEnglishContent ? ' | ' + uploadEnglishContent : ''}`}
          </label>
        </div>
      }
      <p className={`error ${isFileUpload && 'fileUploadError'}`}>{errors[name] || ''}</p>
    </div>
  );
};

export default InputComponent;