import content from "../helper/content";

const httpUtil = require("./httpUtils");
const getCampaign =
  process.env.REACT_APP_GET_CAMPAIGN ||
  "https://api-nonprod.pgsvc.com/gwe/program?programId=[programId]&locale=[locale]";
const getProducts =
  process.env.REACT_APP_GET_PRODUCTS ||
  "https://api-test.pg.com/brandbuilding/consumer/v1/incentives/touchpoint/preprod/warranty/products?programId=[programId]&categoryId=&eanCode=&locale=[locale]&intentCode=";
const receiptUpload =
  process.env.REACT_APP_RECEIPT_UPLOAD ||
  "https://api-test.pg.com/brandbuilding/consumer/v1/incentives/touchpoint/preprod/media-processing/receipt-upload";
const consumerRegistration =
  process.env.REACT_APP_CONSUMER_REGISTRATION ||
  "https://api-nonprod.pgsvc.com/gwe/consumer";
const registration =
  process.env.REACT_APP_REGISTRATION ||
  "https://api-test.pg.com/brandbuilding/consumer/v1/incentives/touchpoint/preprod/warranty/product-registration";
const programId = 15042;
const locale = "zh-HK";

export async function OrderRegistration(data) {
  let header = {
    headers: {
      channelId: 1526
    }
  };
  return await httpUtil.post(registration, data, header);  
}

export async function getCampaignDetails() {
  const url = getCampaign
    .replace("[programId]", programId)
    .replace("[locale]", locale);
  return await httpUtil.get(url);
}

export async function getProductsDetails() {
  const url = getProducts
    .replace("[programId]", programId)
    .replace("[locale]", locale);
  return await httpUtil.get(url);
}

export async function consumerRegistrationDetails(data) {
  return await httpUtil.post(consumerRegistration, data);
}

export async function createMedia(media) {
  let header = {
    headers: {
      channelId: 1526
    }
  };
  return await httpUtil.post(receiptUpload, media, header);
}

export const consumerApiFormatter = (props) => {
  const { firstName, lastName, mobileNumber, emailAddress } = props;
  const data = {};
  data.program = {};
  data.program["programId"] = programId;
  data.program["locale"] = locale;

  data.consumer = {};
  data.consumer = {
    firstName,
    lastName,
    mobileNumber,
    emailAddress,
  };
  return data;
};

export const registrationApiFormatter = (props) => {
  const {
    reasonForRequest,
    dateOfPurchase,
    purchasedFrom,
    buyInput,
    reasonForRequestInput,
    fileUpload,
    modelNumber,
    optin1,
    firstName,
    lastName,
    mobileNumber,
    emailAddress,
  } = props.formData;
  const date = new Date(dateOfPurchase);
  const currectMonth = date.getMonth() + 1;
  const month =
    currectMonth.toString().length === 2 ? currectMonth : "0" + currectMonth;
  const dopDate =
    date.getDate().toString().length === 2
      ? date.getDate()
      : "0" + date.getDate();
  const dop = date.getFullYear() + "-" + month + "-" + dopDate;
  const data = {};
  data.program = {};
  data.program["programId"] = programId;
  data.program["locale"] = locale;

  data.consumer = {};
  data.consumer["firstName"] = firstName;
  data.consumer["lastName"] = lastName;
  data.consumer["email"] = emailAddress;
  data.consumer["phoneNumber"] = mobileNumber;

  data.product = {};
  data.reasonForRequest =
    reasonForRequest === content.reasonToBuy.options[7]
      ? reasonForRequestInput
      : reasonForRequest;
  let optins = [];
  optins[0] = {};
  optins[1] = {};
  optins[0].optinId = `${optin1 ? "58_06" : "58_01"}`;
  optins[0].optinText = `${optin1 ? content.optInCont_5806 : content.optInCont_5801}`;
  optins[1].optinId = `${optin1 ? "58_07" : "58_04"}`;
  optins[1].optinText = `${optin1 ? content.optInCont_5807 : content.optInCont_5804}`;
  data.optIns=optins;

  let mediaFileDetails = {};
  mediaFileDetails["fields"] = fileUpload;

  const selectedMode = props.productInfo.find(
    (product) => product.name === modelNumber
  );
  data.product["id"] = selectedMode.id;
  data.product["purchaseDate"] = dop;
  data.product["retailerName"] = purchasedFrom === content.whereToBuy.options[8]
  ? buyInput
  : purchasedFrom;
  data.product["mediaFileDetails"] = mediaFileDetails;

  let traits = [];
  traits[0] = {};
  traits[0].traitId = "2712";
  traits[0].value = [firstName];
  traits[1] = {};
  traits[1].traitId = "2720";
  traits[1].value = [lastName];
  traits[2] = {};
  traits[2].traitId = "2782";
  traits[2].value = [mobileNumber];
  traits[3] = {};
  traits[3].traitId = "2774";
  traits[3].value = [emailAddress];
  traits[4] = {};
  traits[4].traitId = "4071";
  traits[4].value = [purchasedFrom.includes('Others (Please Specify)') ? `${purchasedFrom + " | " + buyInput}`: purchasedFrom];
  traits[5] = {};
  traits[5].traitId = "5748";
  traits[5].value = [dop];
  traits[6] = {};
  traits[6].traitId = "5747";
  traits[6].value = [selectedMode.name];
  traits[7] = {};
  traits[7].traitId = "1531";
  traits[7].value = [reasonForRequest.includes('Others (Please Specify)') ? `${reasonForRequest + " | " + reasonForRequestInput}`: reasonForRequest];
  data.traits = traits;
  return data;
};
