const content = {
  title: 'BRAUN',
  bannerLogo: './Banner-Oralb-iO.png',
  bannerDesktopLogo: './Desktop-Banner.jpg',
  bannerMobileLogo: './Mobile-Banner.jpg',
  braunSubTitleLogo: './Banner-Oralb-iO.png',
  // onlyLetterRegex: '^[a-z A-Z]+$',
  phoneNumberPattern: '^[0-9]{8}$',
  // Below comment is for including the pattern rules
  // eslint-disable-next-line
  emailPattern: '^[a-z A-Z 0-9]+([\.+_-]?[a-z A-Z 0-9]+)*@[a-z A-Z 0-9]+([\.-]?[a-z A-Z 0-9]+)*(\.[a-z A-Z 0-9]{2,})+$',
  simpleRegistration: '額外3年保養登記',
  thanksRegistration: '感謝登記',
  HKAdditionalYear: '立即登記獲額外3年',
  factoryMaintananceYear: '香港原廠保養 (連同基本2年保養，共5年)',
  registrationQualificationUsageMethods: '登記資格及使用辦法：',
  braunBannerContent: '由即日起至2025年6月30日，於各大香港特約經銷商購買Oral-B iO Series 10, Series 9, Series 8 及 Series 7 智能電動牙刷香港行貨之客戶，並於至2025年7月15日23:59或之前填寫並提交本登記表格，經寶潔公司審核符合條件的登記用戶，即獲得額外3年香港地區Oral-B iO 原廠保養 (連同基本2年保養，共5年)。',
  personalInformation: '個人資料',
  details: '購買詳情',
  firstName: '名',
  lastName: '姓',
  contactNumber: '聯絡電話',
  emailAddress: '電郵地址',
  pleaseSelect: '請選擇',
  whereToBuy: {
    title: '購買地點',
    options: [
      '萬寧 | Mannings',
      '屈臣氏 | Watsons',
      'HKTVmall | HKTVmall',
      '百老滙 | Broadway',
      '豐澤 | Fortress',
      '銅鑼灣Sogo專櫃 | Causeway Bay SOGO',
      '蘇寧 | Suning',
      '中原電器 | Chung Yuen Electrical',
      '其他 (請註明) | Others (Please Specify)'
    ],
    buyInputTitle: '其他 (請註明) | Others (Please Specify)'
  },
  purchaseDate: '購買日期',
  reasonToBuy: {
    title: '現在使用的牙刷型號',
    placeholder: '牙刷型號',
    options: [
      '手動牙刷 | Manual Toothbrush',
      'Oral-B 電動牙刷 | Oralb-B Electric Toothbrush',
      'Philips Sonicare 電動牙刷 | Philips Sonicare Electric Toothbrush',
      'Panasonic 電動牙刷 | Panasonic Electric Toothbrush',
      '高露潔電動牙刷 | Colgate Electric Toothbrush',
      '適齒美聲波震動牙刷 | Systema Sonic Toothbrush',
      '小米電動牙刷 | Xiaomi Electric Toothbrush',
      '其他 (請註明) | Others (Please Specify)'
    ],
    reasonInputTitle: '其他 (請註明) | Others (Please Specify)'
  },
  modelNumber: {
    title: '購買型號'
  },
  uploadReceipt: '上載香港零售商發出的單據或網購收據',
  upload: '上載',
  clearImage: '删除上載',
  termsAndConditions: {
    title: '條款及細則',
    list: [
      '1.)	客戶要求維修服務時，必須出示由各大香港特約經銷商並有列明產品型號之正本購買單據及成功登記的電郵截圖或印刷本，方可獲得自購買日起額外多3年香港地區保養，若期限內沒有於網上登記，將依照購買收據上之日期起提供僅有效2年的保養服務。',
      '2.)  本額外3年香港地區保養計劃會於原廠的2年保用到期日後第一日起生效，並須受原廠保用條款及細則約束。',
      '3.)	商品維修時，請攜帶購買之收據正本，及 Oral-B iO Series 10, Series 9, Series 8 或 Series 7 智能電動牙刷',
      '4.)	所有條款及細則如有任何更改，恕不另行通知。如有任何爭議，寶潔公司保留最終決定權。',
      '5.)	此產品保用期延長優惠只適用於香港地區',
      '6.)	如有任何爭議，寶潔公司擁有最終及對參加者具約束力之決定權',
      '7.)  本登記表格的用戶信息將用於確認和核實用戶享受本額外3年香港地區保養計劃之資格，除非用戶另外同意可將用戶信息用於其他目的。'
    ],
    englistList: [
      'For warranty service, please note that you must have a receipt that includes the date of purchase and confirmation email of extended warranty, in order to be eligible for an extra 3-year local warranty service for Oral-B iO Electric Toothbrush starting on the date of purchase in Hong Kong SAR. If you do not register online before 15 Jul 2025, your Oral-B warranty starts on the day of purchase listed on invoice and only runs for 2 years',
      'The extra 3-year warranty service starts on the next day of the expiry date of 2-year basic warranty. It is subjected to terms and conditions contained in the original manufacturer\'s warranty and the terms and conditions contained herein.',
      'For In Warranty service, please bring along with the Oral-B iO Series 10, Series 9, Series 8 or Series 7 Electric Toothbrush and a copy of your dated proof of purchase.',
      'The Procter & Gamble Company ("P&G") may amend any terms & conditions at any time without prior notice. ',
      'This extension of Warranty Card is only valid for product purchased in Hong Kong SAR',
      'In the event of dispute, P&G reserves the right of final decision.',
      'The use of data collected from the registration form is to confirm and validate users\' eligibility to enjoy an extra 3-year warranty, unless users agree to use and transfer the personal data for direct marketing purpose. '
    ]
  },
  optIns: [
    '您正在註冊成為 香港 ORAL-B CLUB 的用戶。',
    '請認真閱讀寶潔公司及其集團成員企業（“寶潔”）的全球消費者 ',
    '隱私政策',
    '（“消費者隱私政策”）, ',
    '條款與條件',
    '以及',
    '香港特別行政區消費者及網站用戶通知',
    '（統稱為“該文件”）。透過點擊下面的“我同意”或建立賬戶按鈕，我確認並同意:',
    '(i) 我已年滿18歲;',
    '(ii) 我提交我本人和/或我的子女的 (而非他人的) 個人資料予寶潔 ;',
    '(iii) 我已經閱讀、理解，以及接受該文件；以及',
    '(iv) 寶潔將向我發送我已註冊的 ORAL-B CLUB 和/或其他俱樂部的會員簡報、通訊和推廣優惠。',
    '我同意寶潔以該文件所述之直接促銷目的（ORAL-B CLUB 除外），使用和/或轉移我本人和/或我的子女的個人資料。'
  ],
  english: {
    pleaseSelect: 'Please select ',
    warrantyRegistration: 'Extended Warranty Registration',
    HKAdditionalYear: 'Register now to enjoy an extra 3-year ',
    factoryMaintananceYear: '  warranty (A total of 5-year warranty package, including the existing 2-year package)',
    registrationQualificationUsageMethods: 'Registration & Terms and Conditions :',
    braunBannerContent: 'From now to 30 Jun 2025, upon purchase of Oral-B iO Series 10, Series 9, Series 8 and Series 7 Electric Toothbrush from Oral-B HK authorized dealers, and successfully complete the registration on or before 15 Jul 2025, 23:59. You can enjoy an extra 3-year warranty from Oral-B Hong Kong Service Centre (A total of 5-year warranty package, including the existing 2-year package)',
    simpleRegistration: 'Registration',
    thanksRegistration: 'Thank you for registration!',
    personalInformation: 'Personal Information',
    firstName: 'First Name',
    lastName: 'Last Name',
    contactNumber: 'Phone number',
    emailAddress: 'Email address',
    whereToBuy: {
      title: 'Where to buy'
    },
    purchaseDate: 'Date of Purchase',
    modelNumber: {
      title: 'Model no.'
    },
    reasonToBuy: {
      title: 'Current Toothbrush Model',
      placeholder: 'Model'
    },
    uploadReceipt: 'Upload Official Invoice',
    thanksContent: 'We have received your registration! A confirmation email will be sent to your mailbox.',
    optIns: [
      'You are registering as a user of ORAL-B CLUB.',
      'Please read The Procter & Gamble Company’s and its group companies’ (“P&G”) global ',
      'privacy policy',
      ' (the “Privacy Policy”), ',
      'Term & Conditions',
      ' and ',
      'Notice to Consumers and Site Users in Hong Kong',
      ' (collectively, the “Documents”). By clicking the “I AGREE” button below, I confirm and agree that:',
      '(i) I am above 18 years;',
      '(ii) I am submitting my own or my children’s personal data to P&G and not for others; ',
      '(iii) I have read, understood and I accept the Documents; and ',
      '(iv) P&G will send me newsletters, communications and promotional deals for the ORAL-B CLUB and/or club membership for which I have registered.',
      'I  agree to P&G’s use and/or transfer of my and/or my children’s personal data for the direct marketing purposes (other than the ORAL-B CLUB) set out in the Documents. '
    ],
    clearImage: 'Cancel Upload',
    pleaseEnterValidInput: 'Please input valid number',
    pleaseUploadImagePdfFile: 'Please upload file in JPG/ PDF',
    errorInFileUpload: 'Unable to upload',
    pleaseUploadMaximum4MB: '4mb The file size limits to 4MB'
  },
  iAgree: '我同意以上條款及細則',
  submit: '我同意',
  fieldIsRequired: '需填寫內容 | Please fill in the text',
  pleaseEnterValidInput: '請輸入有效之數值 | Please enter valid input',
  pleaseUploadImagePdfFile: '請上載圖片(JPG/PDF) | Please upload file in JPG/ PDF',
  errorInFileUpload: '上載檔案錯誤 | Unable to upload',
  pleaseUploadMaximum4MB: '最大檔案尺寸: 4mb | The file size limits to 4MB',
  thanksContent: '已收到你的登記！你將收到成功登記的電子郵件。',
  optInCont_5801: 'By clicking the “I AGREE” button below, I confirm and agree that: (i)I am above 18 years;(ii) I am submitting my own and/or my children’s personal data to P&G and not for others; and (iii)I have read, understood and I accept the Privacy Policy, Terms & Conditions and Notice to Consumers and Site Users in Hong Kong. (iv)P&G will send newsletter, communication and promotion information to my registered Living Artist Member I agree to P&G’s use and/or transfer of my and/or my children’s personal data for the direct marketing purposes set out in the Privacy Policy, Terms & Conditions and Notice to Consumers and Site Users in Hong Kong.',
  optInCont_5804: 'You are registering as a user of Oral-B HK Club. Please read The Procter & Gamble Company’s and its group companies’ (“P&G”) global privacy policy (the “Privacy Policy”), Term & Conditions and Notice to Consumers and Site Users in Hong Kong [ZL1] (collectively, the “Documents”). By clicking the “I AGREE” button below, I confirm and agree that:    (i)        I am above 18 years;  (ii)        I am submitting my own or my children’s personal data to P&G and not for others; and (iii)        I have read, understood and I accept the Documents; and  (iv)        P&G will send me newsletters, communications and promotional deals for the [BRAND] Club and/or club membership for which I have registered. □   I agree to P&G’s use and/or transfer of my and/or my children’s personal data for the direct marketing purposes (other than the [BRAND] Club) set out in the Documents.',
  optInCont_5806: 'You are registering as a user of Oral-B HK Club. Please read The Procter & Gamble Company’s and its group companies’ (“P&G”) global privacy policy (the “Privacy Policy”), Term & Conditions and Notice to Consumers and Site Users in Hong Kong [ZL1] (collectively, the “Documents”). By clicking the “I AGREE” button below, I confirm and agree that:    (i)        I am above 18 years;  (ii)        I am submitting my own or my children’s personal data to P&G and not for others; and (iii)        I have read, understood and I accept the Documents; and (iv)        P&G will send me newsletters, communications and promotional deals for the [BRAND] Club and/or club membership for which I have registered. □   I agree to P&G’s use and/or transfer of my and/or my children’s personal data for the direct marketing purposes (other than the [BRAND] Club) set out in the Documents.',  
  optInCont_5807: 'You are registering as a user of Oral-B HK Club. Please read The Procter & Gamble Company’s and its group companies’ (“P&G”) global privacy policy (the “Privacy Policy”), Term & Conditions and Notice to Consumers and Site Users in Hong Kong (collectively, the “Documents”). By clicking the “I AGREE” button below, I confirm and agree that:(i) I am above 18 years;(ii) I am submitting my own or my children’s personal data to P&G and not for others; and(iii) I have read, understood and I accept the Documents; and(iv) P&G will send me newsletters, communications and promotional deals for the [brand] Club and/or club membership for which I have registered.□ I agree to P&G’s use and/or transfer of my and/or my children’s personal data for the direct marketing purposes (other than the [brand] Club) set out in the Documents. '  
};

export default content;